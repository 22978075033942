import React, { useState } from "react";
import { get } from "lodash";
import { Restaurant } from "@mui/icons-material";
import Button from "../Button";
import Select from "../Inputs/Select";
import * as styles from "./index.module.scss";
import { convertMapboxPlaceToUserAddress } from "../../utils/geoLocation";
import  MapboxAddressInput from "../FindLocationView/address-input";

const ServingOption = ({
  availableServingOptions,
  selectedServingOption,
  appStyles,
  T,
  branch,
  onSetServingOption,
  onClose,
  order
}) => {
  const [servingOptionId, setServingOptionId] = useState(selectedServingOption && selectedServingOption.id);
  const [userAddress, setUserAddress] = useState(get(order, "deliveryAddress"));

  const onSubmit = () => {
    const newServingOption = availableServingOptions.find(({ id }) => id === servingOptionId);
    onClose();
    onSetServingOption(newServingOption, userAddress);
  }

  const onSelectedMapboxAddress = (place) => {
    if (place.savedAddress){
      setUserAddress({
          ..._.pick(place, [
            "geoPoint",
            "zipCode",
            "city",
            "number",
            "street",
            "state",
            "description",
          ]),
          isGeometricCenter: true,
        });
      }else{
        setUserAddress(convertMapboxPlaceToUserAddress(place));
      }
  };

  const handleSelectServingOption = (servingOption) => {
    setServingOptionId(servingOption);
  }

  const options = availableServingOptions.map(({ id, servingOptionTag }) => ({
    value: id,
    label: servingOptionTag,
  }));

  const currentSelectedServingOption = availableServingOptions.find(({ id }) => id === servingOptionId);
  const shouldShowDeliveryAddress = get(currentSelectedServingOption, "needsAddress", false);

  const canContinue = currentSelectedServingOption && (!shouldShowDeliveryAddress || get(userAddress, "geoPoint"));

  return (
    <React.Fragment>
      <Restaurant
        className={styles.icon}
        style={{ color: appStyles.accentColor }}
      />
      <h2 style={{ textAlign: "center", color: appStyles.accentColor }}>
        {branch.name}
      </h2>
      <div
        className={styles.wrapper}
        style={{ color: get(appStyles, "Card.color") }}
      >
        <span style={{ ...appStyles.CardTitle, fontSize: "1.5rem" }}>
          {T("How would you like your order?")}
        </span>
        <span style={{ marginBottom: 16, marginTop: 6 }}>
          {T(
            "Please select a serving option to begin ordering"
          )}
        </span>
      </div>
      <Select
        options={options}
        value={servingOptionId}
        appStyles={appStyles}
        onChange={handleSelectServingOption}
        
      />

      {shouldShowDeliveryAddress && (
        <MapboxAddressInput
          usingCurrentLocation={false}
          setUsingCurrentLocation={()=>{}}
          onSelected={onSelectedMapboxAddress}
          placeholder={ T("Enter address with street number")}
          T={T}
          appStyles={appStyles}
          locationBias={_.get(branch, "geoPoint")}
          showLocateMe={false}
          onLocateMeFailure={()=>{}}//this.handleLocateMeError}
          onLocateMeSuccess={()=>{}}//this.handleNewUserCoords}
          autoLocateMeOnMount={false}
          shouldAllowInaccurateAddress={false}
          shouldShowHouseNumberInput={true}
          setAddressWithHouseNumber={(houseNumber) => {}}
          userEnteredInvalidHouseNumber={()=>{}}
          initialValue={null}
          accurateOnly={true}
          onClear={()=>{setUserAddress(null)}}
          // previousAddresses={previousAddresses}
        />
      )}

      <Button
        appStyles={appStyles}
        centered
        style={{ marginTop: 16, ...!canContinue ? { backgroundColor: "#cccccc" } : {} }}
        onClick={canContinue ? onSubmit : () => {}}
      >
        {T("Continue order")}
      </Button>
    </React.Fragment>
  );
};

export default ServingOption;
